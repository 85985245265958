const {pipe, replace, toLower, trim} = require('ramda')
const sacaPuntos    = replace(/[.,;!¡?¿:«»\/()"-']+/ig, ' ')
const aplaneAes     = replace(/[áàä]/gi, 'a')
const aplaneEes     = replace(/[éèë]/gi, 'e')
const aplaneIes     = replace(/[íìï]/gi, 'i')
const aplaneOes     = replace(/[óòö]/gi, 'o')
const aplaneUes     = replace(/[úùü]/gi, 'u')
const sacaEspacios  = replace(/[ ]+/g, ' ')
const sacaEmoji     = replace('🙂', '')
const sacaAcentos   = pipe(aplaneAes, aplaneEes, aplaneIes, aplaneOes, aplaneUes)
module.exports      = pipe(sacaEmoji, toLower, sacaPuntos, sacaAcentos, sacaEspacios, trim)

