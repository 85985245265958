import React from 'react'
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import Encabezamiento from './encabezamiento'
import Pie from './pie'
import styled from '@emotion/styled'
const susanaTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: '#16455a',
    },
    secondary: {
      main: '#f44336',
    },
  },
})

const ContenedorMarco = styled.div`width:100%; overflow:hidden`

const Marco = ({children}) =>
  <ThemeProvider theme={susanaTheme}>
    <ContenedorMarco>
      <Encabezamiento />
      {children}
      <Pie />
    </ContenedorMarco>
  </ThemeProvider>
export default Marco
