import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { blue } from '@material-ui/core/colors'
import aplane from '../utiles/aplane'
import { buscar } from '../utiles/da'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
// import {navigate} from 'gatsby'

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
    width: '100%',
    maxWidth: '144ch',
    backgroundColor: theme.palette.background.paper,
    color: 'black',
  },
  listabox: {
    margin: theme.spacing(3),
    fontSize: '1rem',
  },
  listatbox: {
    margin: theme.spacing(3),
  },
  closeBox: {
    height: 72,
  },
  closeButton: {
    position: 'absolute',
    margin: 16,
    right: 0,
  },
  closes: {
    fontSize: 32,
  },
  inline: {
    display: 'inline',
  },
}))

const cargarLista = async (claves, setProcesando, setLista) => {
  setProcesando(true)
  await buscar(claves).then(laLista => {
    setLista(laLista)
    setProcesando(false)
  })
}

function Search({ onClose, textoDeBusqueda, values, open }) {
  const classes = useStyles()
  const [procesando, setProcesando] = useState(false)
  const [lista, setLista] = useState([])
  useEffect(() => {
    const aplanado = aplane(textoDeBusqueda)
    const claves = aplanado.split(' ')
    if (claves) cargarLista(claves, setProcesando, setLista)
  }, [textoDeBusqueda])

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = value => {
    location.href = `/${value}`
    // navigate(`/${value}`)
  }

  const Lista = () => (
    <List className={classes.root}>
      {lista ? (
        lista.map((entrada, indice) => {
          const url = `${entrada.nombre}/#${entrada.tagid}`
          console.log(url)
          return (
            <div key={indice}>
              <ListItem alignItems="flex-start" button onClick={() => handleListItemClick(url)}>
                <ListItemAvatar>
                  <Avatar alt="Remy Sharp" src={entrada.src ? `${entrada.src}` : null} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    entrada.title ? entrada.title : entrada.imgTitle ? entrada.imgTitle : null
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {entrada.speech}
                      </Typography>
                      <div>
                        <br />
                        {`En el artículo "${entrada.nombre}"`}
                      </div>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </div>
          )
        })
      ) : (
        <div>...</div>
      )}
    </List>
  )

  return (
    <Dialog onClose={handleClose} aria-labelledby="search-title" open={open}>
      <div className={classes.closeBox}>
        <Button className={classes.closeButton} onClick={handleClose}>
          <CloseIcon className={classes.closes} />
        </Button>
      </div>
      <DialogTitle id="search-title">
        {lista && lista.length > 0 ? (
          `Hay ${lista.length} resultados para la búsqueda de "${values.textoDeBusqueda}"`
        ) : values.textoDeBusqueda.length < 1 ? (
          <div className={classes.listatbox}>{`Ok. Yo busco. Pero dame algo...`}</div>
        ) : (
          <div className={classes.listatbox}>{'¡He fracasado!'}</div>
        )}
      </DialogTitle>
      <div className={classes.listabox}>
        {procesando ? (
          <div>Procesando...</div>
        ) : lista.length > 0 ? (
          <Lista />
        ) : values.textoDeBusqueda.length < 1 ? (
          <div className={classes.listabox}>
            {
              'Ahí, a la izquierda de la lupita en la que hiciste clic. Ponele una o más palabras y las busco en el sitio. Gracias.'
            }
          </div>
        ) : (
          <div
            className={classes.listabox}
          >{`No encontré nada relacionado con "${values.textoDeBusqueda}"`}</div>
        )}
      </div>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Search
