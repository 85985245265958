import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faYoutube,
  faFacebook,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import { Link } from 'gatsby'
import SubrayadoCuandoPasanPorAhi from './estilos/subrayado-cuando-pasan-por-ahi'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Telefono = styled.div`
  flex: 1;
  justify-content: left;
  display: flex;
`
const telefonotelefono = css`
  width: 100%;
  text-align: left;
`
const redessociales = css`
  font-size: 12px;
  text-decoration: none;
`
const faiconito = css`
  padding-right: 4px;
`
const faiconitofacebook = css`
  padding-right: 5px;
`
const faiconitoinstagram = css`
  padding-right: 6px;
`
const EstiloPie = styled.div`
  display: flex;
  flex-basis: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 12px;
  color: white;
  background-color: #16455a;
  height: 50rem;
  line-height: 1.5rem;
  & a,
  & a:active,
  & a:visited,
  & a:any-link {
    color: white;
  }
`
const autora = css`
  color: red;
`
const Politica = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`
const politicapolitica = css`
  width: 100%;
  text-align: center;
`
const Direccion = styled.div`
flex: 1;
display: flex;
justify-content: right;
`
const direcciondireccion = css`
  width: 100%;
  text-align: right;
`
const emailicon = css`
  font-size: 13px;
  `
const EmailIconWrapper = styled.div`
  float:left;
  width: 17px;
  padding-top: 3px;
  height: 13px;
  `
const Email = styled.span`
  font-size: 8px;
  padding-left: 3px;
  `
const Pie = () => (
  <EstiloPie>
    <Telefono>
      <div css={telefonotelefono}>
        ☎ (011) 4543-5278 <br />
        <EmailIconWrapper>
          <EmailIcon css={emailicon} />
        </EmailIconWrapper>
        <Email>info@susanacanel.com</Email>
        <br />
        <a href="https://www.youtube.com/susanacanel" css={redessociales}>
          <span>
            <FontAwesomeIcon icon={faYoutube} css={faiconito} />
            YouTube
          </span>
        </a>
        <br />
        <a href="https://www.facebook.com/romatoursusy/" css={redessociales}>
          <span>
            <FontAwesomeIcon icon={faFacebook} css={faiconitofacebook} />
            Facebook
          </span>
        </a>
        <br />
        <a href="https://instagram.com/canelsusana/" css={redessociales}>
          <span>
            <FontAwesomeIcon icon={faInstagram} css={faiconitoinstagram} />
            Instagram
          </span>
        </a>
        <br />
      </div>
    </Telefono>
    <Politica>
      <div css={politicapolitica}>
        <span style={{ paddingRight: 3 }}>Copyright ©</span>
        <Link to="/sobremi/" activeClassName="perfil" className="perfil">
          <SubrayadoCuandoPasanPorAhi>Susana Canel</SubrayadoCuandoPasanPorAhi>
        </Link>
        <br />
        <Link to="/politica/" activeClassName="perfil" className="perfil">
          <SubrayadoCuandoPasanPorAhi>
            Política de privacidad
          </SubrayadoCuandoPasanPorAhi>
        </Link>
        <br />
        <span css={autora}>Sitio creado por: Susana Canel</span>
      </div>
    </Politica>
    <Direccion>
      <div css={direcciondireccion}>
        Belgrano <br />
        Buenos Aires <br />
        Argentina
      </div>
    </Direccion>
  </EstiloPie>
)

export default Pie
