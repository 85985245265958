import React, { useState } from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import iconoSusana from '../img/inicio/susanacanel2.png'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Search from './search'

// #region Mis Estilos
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
    zIndex: 100,
  },
  search: { color: 'white' },
  eslabon: {
    marginLeft: theme.spacing(2),
  },
  iconoSusana: {
    margin: 0,
    padding: 0,
    paddingTop: 5,
    height: '2.8rem',
    backgroundColor: 'transparent',
  },
  menuButton: {
    margin: 0,
    padding: 0,
    marginRight: theme.spacing(1),
    minWidth: 48,
  },
  titulo: {
    display: 'none',
    paddingTop: 7,
    fontSize: '1.8rem',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  boxbuscar: {
    marginTop: 6,
    marginBottom: 6,
    padding: 4,
    paddingLeft: 10,
    marginLeft: 10,
    width: 180,
    // marginRight: theme.spacing(20),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  menucito: {
    backgroundColor: theme.palette.primary,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: 10,
    paddingTop: 10,
    display: 'inline-block',
    color: theme.palette.common.white,
    '&:visited': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      color: '#ffffffd8',
    },
    [theme.breakpoints.down('sm')]: {
      '&:hover': {
        color: '#71bfd0',
        backgroundColor: fade(theme.palette.common.white, 0.1),
      },
      borderBottom: '1px solid #ffffff07',
      width: '96%',
      marginRight: '1rem',
      marginLeft: '1rem',
      paddingLeft: '1rem',
    },
  },
  appbar: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
    paddingRight: 0,
    fontSize: '12px',
    height: '44px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      paddingBottom: '4px',
    },
  },
  izquierda: {
    display: 'flex',
    width: '40%',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  izquierdaizquierda: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
  },
  izquierdaderecha: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {},
  },
  boton: {
    padding: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  derecha: {
    display: 'flex',
    width: '60%',
    flexWrap: 'wrap',
    lineHeight: '2rem',
    marginRight: theme.spacing(4),
    justifyContent: 'flex-end',
    marginBottom: 'auto',
    marginTop: 'auto',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1rem',
      lineHeight: '1.2rem',
    },
  },
  derechaApagada: {
    width: '60%',
    display: 'flex',
    flexWrap: 'wrap',
    lineHeight: '2rem',
    paddingRight: theme.spacing(4),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      width: '100%',
    },
  },
  hamburguesa: {
    fontSize: '2.4rem',
    marginLeft: '4rem',
    marginRight: '2rem',
  },
  botonBuscar: {
    color: 'white',
    marginLeft: 0,
    paddingLeft: 0,
    width: 36,
    minWidth: 36,
    '& .MuiButton-label': {
      width: 36,
    },
    '& .MuiButton-text': {
      padding: 0,
    },
    '& .MuiButton-root': {
      minWidth: 36,
    },
  },
}))
const AlturaFija = styled.div`
  height:     344px !important,
  min-height: 344px !important;
  display:block;
`
// #endregion

// #region Mis Componentes: Buscar, IconoSusana, TextoSusana, RegionIzquierda, RegionIzquierdaIzquierda, RegionIzquierdaDerecha, RegionDerecha
const IconoSusana = ({ classes }) => (
  <AlturaFija>
    <Button edge="start" className={classes.menuButton} color="inherit" aria-label="open drawer">
      <img src={iconoSusana} className={classes.iconoSusana} alt="no tá" />
    </Button>
  </AlturaFija>
)

const TextoSusana = ({ classes }) => (
  <Typography className={classes.titulo} variant="h5" noWrap>
    <Link to="/sobremi/" activeClassName="perfil" className="perfil">
      Susana Canel
    </Link>
  </Typography>
)

const RegionIzquierda = ({ classes, children }) => (
  <div className={classes.izquierda}>{children}</div>
)

const RegionIzquierdaIzquierda = ({ classes, children }) => (
  <div className={classes.izquierdaizquierda}>{children}</div>
)
const RegionIzquierdaDerecha = ({ classes, children }) => (
  <div className={classes.izquierdaderecha}>{children}</div>
)

const RegionDerecha = ({ menuState, classes, children }) => {
  return <div className={menuState ? classes.derecha : classes.derechaApagada}>{children}</div>
}

// #endregion

const Encabezamiento = () => {
  const classes = useStyles()
  const [menuState, setMenuState] = useState(0)
  const [textoDeBusqueda, setTextoDeBusqueda] = useState('')
  const [searchOpen, setSearchOpen] = useState(false)
  const [values, setValues] = React.useState({
    textoDeBusqueda: '',
  })
  const alternarEstadoDelMenu = () => {
    setMenuState(Math.abs(menuState - 1))
  }
  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }
  const handleClose = () => setSearchOpen(false)
  const handleClick = () => {
    setTextoDeBusqueda(values.textoDeBusqueda)
    setSearchOpen(true)
  }
  // #region Render
  return (
    <>
      <AppBar position="static" className={classes.appbar}>
        <RegionIzquierda classes={classes}>
          <RegionIzquierdaIzquierda classes={classes}>
            <IconoSusana classes={classes} />
            <TextoSusana classes={classes} />
          </RegionIzquierdaIzquierda>
          <RegionIzquierdaDerecha classes={classes}>
            {/* <InputBuscar classes={classes} lanzarLaBusqueda={lanzarLaBusqueda}/> */}
            <FormControl fullWidth className={classes.boxbuscar} size="small">
              <InputBase
                className={classes.search}
                value={values.textoDeBusqueda}
                onChange={handleChange('textoDeBusqueda')}
                placeholder="Buscar..."
              />
            </FormControl>
            <Button onClick={handleClick} className={classes.botonBuscar}>
              <SearchIcon />
            </Button>
            <IconButton
              className={classes.boton}
              aria-label="show more"
              aria-haspopup="true"
              color="inherit"
              onClick={alternarEstadoDelMenu}
            >
              <MenuIcon className={classes.hamburguesa} />
            </IconButton>
          </RegionIzquierdaDerecha>
        </RegionIzquierda>
        <RegionDerecha menuState={menuState} classes={classes}>
          <Link
            className={classes.link}
            activeClassName="activo"
            onClick={alternarEstadoDelMenu}
            to="/"
          >
            Inicio
          </Link>
          <Link
            className={classes.link}
            activeClassName="activo"
            onClick={alternarEstadoDelMenu}
            to="/sobremi/"
          >
            Sobre mí
          </Link>
          <Link
            className={classes.link}
            activeClassName="activo"
            onClick={alternarEstadoDelMenu}
            to="/blog-vlog/"
          >
            Blog/Vlog
          </Link>
          <Link
            className={classes.link}
            activeClassName="activo"
            onClick={alternarEstadoDelMenu}
            to="/asesoramiento/"
          >
            Asesoramiento
          </Link>
          <Link
            className={classes.link}
            activeClassName="activo"
            onClick={alternarEstadoDelMenu}
            to="/contactame/"
          >
            Contáctame
          </Link>
          <Link
            className={classes.link}
            activeClassName="activo"
            onClick={alternarEstadoDelMenu}
            to="/subscribete/"
          >
            Subscríbete
          </Link>
        </RegionDerecha>
      </AppBar>
      <Search
        values={values}
        open={searchOpen}
        textoDeBusqueda={textoDeBusqueda}
        onClose={handleClose}
      />
    </>
  )
  // #endregion
}

export default Encabezamiento
